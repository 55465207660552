<template>
  <div class="onboarding-content-wrapper">
    <div class="onboarding-mingle">
      <h2 class="onboarding-content-title">Desired accessibility?</h2>
      <p>Pick your level of social.</p>
      <IconConnectivity
        class="onboarding-mingle-icon"
        :data-level="activeId"
        style="justify-self: center; padding: 0 4rem; margin-top: 1rem"
        height="180"
        width="100%"
      />
      <p style="padding: 0 4rem; line-height: 1.2; min-height: 40px">
        {{ activeItem.text }}
      </p>
      <div
        style="
          position: relative;
          display: flex;
          align-items: center;
          z-index: 0;
        "
      >
        <div class="slider-ticks">
          <div
            v-for="num in max"
            :key="num"
            :data-hide="num === activeId"
          ></div>
        </div>
        <form-input
          v-model="activeId"
          type="range"
          name="mingle-slider"
          :min="min"
          :max="max"
        />
      </div>
    </div>
    <XButton type="large" style="margin-top: auto" @click="next">Next</XButton>
  </div>
</template>

<script>
import IconConnectivity from '../../components-rf/icons/onboarding/IconConnectivity.vue'
import XButton from '../../components/common/global/XButton.vue'

const list = [
  {
    id: 1,
    text: 'Carrier pigeons.',
  },
  {
    id: 2,
    text: 'No thanks. No contact.',
  },
  {
    id: 3,
    text: 'Some light social media-ing.',
  },
  {
    id: 4,
    text: 'Must. Stream. TV.',
  },
  {
    id: 5,
    text: 'Must post on Instagram.',
  },
]

export default {
  components: { XButton, IconConnectivity },
  name: 'OnboardingConnectivity',
  created() {
    const idList = list.map(el => el.id)
    this.min = this.activeId = Math.min.apply(Math, idList)
    this.max = Math.max.apply(Math, idList)
  },
  data() {
    return {
      activeId: 0,
      list,
      min: 0,
      max: 0,
    }
  },
  computed: {
    activeItem() {
      return this.list.find(({ id }) => id === this.activeId)
    },
  },
  methods: {
    next() {
      this.$router.push('/onboarding/outro')
    },
  },
}
</script>

<style lang="scss">
.onboarding-mingle {
  display: grid;
  gap: 1rem;
}
.slider-ticks {
  position: absolute;
  z-index: -1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  & > div {
    height: 10px;
    width: 2px;
    background-color: #000;
    position: relative;
    &[data-hide='true'] {
      opacity: 0;
    }
  }
}
.onboarding-mingle-icon[data-level='2'] > path:nth-child(1),
.onboarding-mingle-icon[data-level='3'] > path:nth-child(-n + 2),
.onboarding-mingle-icon[data-level='4'] > path:nth-child(-n + 3),
.onboarding-mingle-icon[data-level='5'] > path:nth-child(-n + 4) {
  fill: var(--color-primary);
}
</style>
